import { Router } from '@reach/router'
import News from '../components/News'

const Novinky = () => {
  return (
    <Router>
      <News {...({ path: '/novinky/:page' } as PagePropsPagination)} />
      <News {...({ path: '/novinky/' } as PagePropsPagination)} />
    </Router>
  )
}
export default Novinky
