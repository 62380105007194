import useArticles from '../hooks/useArticles'
import Article from './Article'
import Screen from './Screen'
import Pagination from './Pagination'

const News = ({ uri, page = '1', ...rest }: PagePropsPagination) => {
  const parsedPage = parseInt(page)
  const { articles, numberOfPages } = useArticles(parsedPage)

  return (
    <Screen uri={uri} {...rest}>
      <h2 className="h2-medium divider pb-4 mb-4">Novinky</h2>
      {articles.map(({ title, content }, index) => (
        <Article
          title={title}
          content={content}
          isLast={index === articles.length - 1}
          key={title}
        />
      ))}
      <Pagination numberOfPages={numberOfPages} page={parsedPage} type="news" />
    </Screen>
  )
}

export default News
